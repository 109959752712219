<template>
  <a-card
    :style="{ marginTop: '2em' }"
    :editable="false"
    :header="$t('common.notes')"
  >
    <note
      v-for="note in orderedNotes"
      :key="note.id"
      :note="note"
    />
  </a-card>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import Note from './Note'

export default {
  components: {
    Note
  },
  computed: {
    orderedNotes () {
      return _.orderBy(_.map(this.doc.notes, (note, date) => {
        note.created_at = moment(date)
        return note
      }), ['created_at'], ['desc'])
    }
  }
}
</script>
