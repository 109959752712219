<template>
  <div class="modal-card">
    <header
      class="modal-card-head"
    >
      <p class="modal-card-title">
        {{ $t('failures.repairListForm.header') }}
      </p>
      <span
        class="is-pulled-right buttons"
      >
        <b-button
          icon-left="arrow-up"
          @click="() => $parent.close()"
        >{{ $t('common.close') }}</b-button>

        <create-repair-list-button
          :file-path="`failures/${doc.id}`"
          :node="doc.node"
          :parts="parts"
          :failure="doc"
          :before-list-creating="beforeListCreating"
          @created="repairListCreated"
        />
      </span>
    </header>

    <section class="modal-card-body">
      <a-input
        :manual-doc="manualDoc"
        field="node.name"
        :label="$t('failures.ref.name')"
        type="textarea"
      />
      <b-field>
        <a-input
          :manual-doc="manualDoc"
          field="node.service.inspection"
          :label="$t('failures.props.service.inspection')"
          type="number"
          min="0"
        />

        <a-input
          :manual-doc="manualDoc"
          field="node.service.repair"
          :label="$t('failures.props.service.repair')"
          type="number"
          min="0"
        />

        <a-input
          :manual-doc="manualDoc"
          field="node.service.testing"
          :label="$t('failures.props.service.testing')"
          type="number"
          min="0"
        />
      </b-field>

      <a-input
        :manual-doc="manualDoc"
        field="node.notes"
        :label="$t('common.notes')"
        type="textarea"
      />
    </section>
    <footer class="modal-card-foot">
      <button
        class="button is-default"
        @click="close"
      >
        {{ $t('common.close') }}
      </button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { random, includes, pick, concat, forEach, get } from 'lodash'
import CreateRepairListButton from '@/components/CreateRepairListButton'

export default {
  components: {
    CreateRepairListButton
  },
  data() {
    return {
      creating: false
    }
  },
  computed: {
    ...mapGetters({
      allFailureParts: 'failureParts/currentFailureRootItems',
      allFailureNodes: 'failureNodes/currentFailureRootItems'
    }),
    parts() {
      return _.concat(this.allFailureNodes, this.allFailureParts)
    }
  },
  created() {
    forEach(['inspection', 'repair', 'testing'], (key) => {
      const value = get(this.doc, `node.service.${key}`)
      console.log('typeof', typeof value)
      if(typeof value !== 'number' && typeof value !== 'string') {
        console.warn('dispatching', key)
        this.$store.dispatch('currentDoc/set', {
          field: `node.service.${key}`,
          value: this.$store.getters["failures/hours"](key)
        })
      }
    })
  },
  methods: {
    beforeListCreating(settings) {
      const { empty } = settings

      if(!empty) {
        this.$store.dispatch('currentDoc/set', {
          field: 'node.generated_by',
          value: pick(this.user, ['id', 'name'])
        })

        this.$store.dispatch('currentDoc/set', {
          field: 'node.generated_at',
          value: new Date()
        })
      }
    },
    async repairListCreated (settings) {
      this.$parent.$parent.$data.filesKey = random(1, 99999)
      this.close()
    },
    close () {
      this.$emit('close', this.doc)
    }
  }
}
</script>
