<template>
  <div>
    <div
      v-if="initialDocState && initialDocState.state == 'draft' && user.role ==='customer'"
    >
      <new-failure />
    </div>

    <template
      v-else
    >
      <div
        v-if="doc"
      >
        <!-- <button-bar/> -->
        <div
          class="columns"
        >
          <div class="column">
            <div class="buttons is-pulled-right">
              <b-button
                v-if="(user.role === 'admin' || user.role === 'technician') && canComplete && doc.state !== 'completed'"
                tag="a"
                icon-left="check-all"
                @click="complete"
              >
                {{ $t('failures.form.accomplish') }}
              </b-button>
              <b-button
                v-if="(user.role === 'admin' || user.role === 'technician') && doc.state !== 'completed'"
                tag="a"
                @click="createRepairList"
              >
                {{ $t('failures.repairListForm.generate_repair_list') }}
              </b-button>
              <b-button
                v-if="user.role === 'admin'"
                tag="a"
                type="is-danger"
                icon-left="delete"
                @click="remove"
              >
                {{ $t('common.delete') }}
              </b-button>
            </div>
          </div>
        </div>
        <div class="is-clearfix" />
        <div class="columns">
          <div class="column">
            <vehicle
              field="vehicle"
            />
            <state />
            <service />
          </div>

          <div class="column">
            <contacts
              v-if="keys(doc.contacts).length"
              :header="$t('failures.form.external_contacts')"
              :options="operatorContacts"
              :permanent-document="permanentDocument"
            />
            <contacts
              v-if="keys(doc.team).length"
              :header="$t('failures.service_team')"
              collection-path="team"
            />
            <files
              :key="filesKey"
            />
            <mails />
            <notes
              v-if="keys(doc.notes).length"
            />
          </div>
        </div>

        <progress-bar />

        <failure-parts
          @selectParent="selectParent"
        />

        <add-button
          v-if="canUpdate"
          ref="addButton"
          :parent="parent"
        />
      </div>
    </template>
    <!-- <rough-document
        /> -->
  </div>
</template>

<script>

// @ is an alias to /src
import { mapState, mapGetters, mapActions } from 'vuex'
import { toPairs, get, keys, random } from 'lodash'
import _ from 'lodash'

import ColumnsSorter from '@/components/ColumnsSorter'

import NewFailure from './Failure/NewFailure'
import Service from './Failure/Service'
import State from './Failure/State'
import Vehicle from './Failure/Vehicle'
import ProgressBar from './Failure/ProgressBar'
import Contacts from '@/components/Contacts/Contacts'
import Files from '@/components/Files'
import Notes from './Failure/Notes'
import Mails from './Failure/Mails'
import FailureParts from './Failure/FailureParts'
import AddButton from './Failure/AddButton'
import RepairListForm from './Failure/RepairListForm'

export default {
  components: {
    ColumnsSorter,
    NewFailure,
    Service,
    State,
    Vehicle,
    ProgressBar,
    Contacts,
    Files,
    Notes,
    Mails,
    FailureParts,
    AddButton
  },
  data () {
    return {
      initialDocState: null,
      next: null,
      parent: false,
      filesKey: random(1,99999)
    }
  },
  computed: {
    ...mapGetters({
      canUpdate: 'currentDoc/canUpdate'
    }),
    canComplete() {
      return !(_.some(this.$store.getters["failureParts/currentFailureItems"], item => !(item.state === 'repaired' || item.state === 'completed')) || _.some(this.$store.getters["failureNodes/currentFailureItems"], item => !(item.state === 'repaired' || item.state === 'completed')))
    },
    operatorContacts() {
      let operator = _.find(this.$store.getters["operators/items"], { id: this.doc.operator.id})
      return operator ? operator.contacts : {}
    },
    permanentDocument() {
      return {
        collection: 'operators',
        id: this.doc.operator.id,
        path: 'contacts'
      }
    }
  },
  async created () {
    const doc = await this.$store.dispatch('currentDoc/loadDoc')
    if(this.doc.operator && this.doc.operator.id ) {
      await this.$store.dispatch('operators/fetchById', this.doc.operator.id)
    }

    this.initialDocState = _.assign({}, this.doc)

    if(!this.doc.progress.reported.at) {
      this.report()
    }

    this.$store.dispatch('user/updateRecentFailures', this.doc)

  },
  methods: {
    keys,
    selectParent(parent) {
      this.parent = parent
      // TODO ulozit do userSettings
    },
    async remove() {
      const self = this
      let type = 'is-warning'
      let message = this.$t('failures.form.remove_warning')
      if(_.some(this.$store.getters["failureParts/currentFailureItems"], item => item.failureSupply)) {
        message += this.$t('failures.form.remove_warning_2')
        type = 'is-danger'
      }
      message += this.$t('failures.form.remove_warning_3')
      this.$buefy.dialog.confirm({
        message,
        type,
        confirmText: this.$t('common.delete'),
        onConfirm: async () => {
          await self.$store.dispatch('currentDoc/deleteWithChildren')
          self.$router.push({ name: 'failures' })
          self.$buefy.toast.open({
            message: this.$t('failures.form.remove_success'),
            position: 'is-bottom',
            type: 'is-success'
          })
        }
      })
    },
    async complete() {
      const self = this
      let message = this.$t('failures.form.complete_confirm')
      let type = 'is-warning'

      this.$buefy.dialog.confirm({
        message,
        type,
        confirmText: this.$t('common.accomplish'),
        onConfirm: async () => {
          await self.$store.dispatch('failures/setCompleted', { id: self.doc.id })
          self.$buefy.toast.open({
            message: this.$t('failures.form.complete_success'),
            position: 'is-bottom',
            type: 'is-success'
          })
        }
      })
    },
    report(next = function() {}) {
      console.log('report')
      const self = this

      this.openDatepicker({
        field: `progress.reported.at`,
        header: this.$t('failures.select_reported'),
        events: {
          close() {
            self.$store.dispatch('currentDoc/set', {
              field: 'progress.reported.by',
              value: _.pick(self.user, ['id', 'name'])
            })
            next()
          }
        }
      })
    },
    estimate(next = function() {}) {
      console.log('estimate')
      const self = this

      this.openDatepicker({
        field: `progress.estimated.at`,
        header: this.$t('failures.select_estimated'),
        events: {
          close() {
            self.$store.dispatch('currentDoc/set', {
              field: 'progress.estimated.by',
              value: _.pick(self.user, ['id', 'name'])
            })
            next()
          }
        }
      })
    },
    checkReportedInfo() {
      if(!this.doc.progress.reported.at) {
        this.report(this.checkReportedInfo)
      }
    },
    createRepairList() {
      this.$buefy.modal.open({
        parent: this,
        component: RepairListForm,
        hasModalCard: true,
        fullScreen: true
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    if(!_.includes(['failurePart', 'failureNode'], to.name) && this.doc && this.doc.state === 'draft') {
      const self = this
      this.$buefy.dialog.confirm({
        message: this.$t('failures.form.before_leave'),
        type: 'is-warning',
        onConfirm: async () => {
          await self.$store.dispatch('currentDoc/set', {
            field: 'state',
            value: 'reported'
          })
          next()
        }
      })
    } else {
      next()
    }
  }
}
</script>
