<template>
  <a-card
    :editable="false"
  >
    <template
      #header-title
    >
      <b-icon
        icon="email"
      />
      <span>{{ $t('failures.mails.header') }}</span>
    </template>
    <template
      #header-buttons
    >
      <b-button
        v-if="user.role === 'admin' && needsReceipt"
        type="is-success"
        @click="() => sendMail('Receipt')"
      >
        {{ $t('failures.mails.send_receipt') }}
      </b-button>

      <b-dropdown
        aria-role="list"
        position="is-bottom-left"
        @input="sendMail"
      >
        <button
          slot="trigger"
          class="button"
        >
          <b-icon icon="dots-vertical" />
        </button>

        <b-dropdown-item
          value="Receipt"
          aria-role="listitem"
        >
          {{ $t('failures.mails.receipt') }}
        </b-dropdown-item>
        <b-dropdown-item
          value="Overview"
          aria-role="listitem"
        >
          {{ $t('failures.mails.overview') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
    <div>
      <mail
        v-for="mail in mails"
        v-if="mails.length"
        :key="mail.id"
        :mail="mail"
      />
      <empty-table
        v-else
      />
    </div>
  </a-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { get, keys, find, random } from 'lodash'
import EmptyTable from '@/components/EmptyTable'
import Receipt from './Mail/Receipt'
import Mail from './Mail'

export default {
  components: {
    Receipt,
    Mail,
    EmptyTable
  },
  computed: {
    ...mapGetters({
      mails: 'mails/currentFailureItems'
    }),
    docProps () {
      return {
        failure: {
          id: this.doc.id
        },
        customer: this.doc ? this.doc.customer : null
      }
    },
    needsReceipt() {
      return this.doc.state === 'reported' && this.doc.progress.estimated.at && !this.doc.notified
    }
  },
  async created() {
    await this.$store.dispatch(`mails/openDBChannel`, { where: [
      ['failure.id', '==', this.doc.id]
    ]})
  },
  methods: {
    get,
    keys,
    sendMail(type) {
      const self = this

      let options = {}
      if(type === 'Overview') {
        type = 'Receipt'
        options.is_final = true
      }
      this.$buefy.modal.open({
        parent: this,
        component: () => import(`./Mail/${type}`),
        trapFocus: true,
        fullScreen: true,
        scroll: 'clip',
        props: {
          docProps: this.docProps,
          options
        },
        events: {
          close(options) {
            if(options.reloadFiles) {
              self.$parent.$data.filesKey = random(1, 99999)
            }
          }
        }
      })
    }
  }
}
</script>
