<style scoped>
  @media print {
    .no-print {
      display: none !important;
    }
  }

  .floating-button-wrapper {
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
  }

  .floating-button {
    border-radius: 50%;
  }
</style>

<template>
  <b-dropdown
    :value="value"
    position="is-top-left"
    class="floating-button-wrapper"
  >
    <a
      slot="trigger"
      class="button floating-button is-success is-large"
      :title="$t('common.add')"
    >
      <b-icon icon="plus" />
    </a>
    <b-dropdown-item
      v-if="errors.length"
      disabled
    >
      {{ $t('failures.addButton.enter_information') }}
      <br><br>
      <span class="has-text-danger">
        {{ errors.toString() }}
      </span>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="!errors.length"
      @click="create('failureNodes')
      "
    >
      {{ $t('failures.addButton.node') }}
    </b-dropdown-item>

    <b-dropdown-item
      v-if="!errors.length"
      @click="create('failureParts')"
    >
      {{ $t('failures.addButton.part') }}
    </b-dropdown-item>

    <b-dropdown-item @click="addNote">
      {{ $t('common.note') }}
    </b-dropdown-item>
    <b-dropdown-item @click="addContact('team')">
      {{ $t('failures.team_member') }}
    </b-dropdown-item>
    <b-dropdown-item @click="addContact('contacts')">
      {{ $t('failures.external_contact') }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { upperCase } from 'lodash'
import FailureNode from '@/views/FailureNode'
import NewNodeOrPart from './NewNodeOrPart'
import NoteForm from './NoteForm'
import ContactForm from '@/components/Contacts/Form'
import makeFailureRef from '@/modules/failures/makeRef'

export default {
  props: {
    parent: {
      type: Object|Boolean,
      required: true
    }
  },
  data () {
    return {
      value: null
    }
  },
  computed: {
    ...mapGetters({
      users: 'users/items'
    }),
    errors() {
      const validations = {
        [this.$t('customers.sg')]: 'doc.customer',
        [this.$t('operators.sg')]: 'doc.operator',
        [this.$t('failures.props.name')]: 'doc.name',
        [this.$t('failures.props.contract_number')]: 'doc.contract_number',
        [this.$t('failureParts.parent.select')]: 'parent'
      }

      let errors = []

      _.forEach(validations, (field, label) => {
        if(field !== 'parent') {
          if(!_.get(this, field)) {
            errors.push(label)
          }
        } else {
          if(this.parent === false) {
            errors.push(label)
          }
        }
      })
      return errors
    }
  },
  methods: {
    upperCase,
    addNote () {
      const id = new Date().toString()
      this.$store.dispatch('currentDoc/set', {
        field: `notes.${id}`,
        value: {
          id,
          created_by: _.pick(this.user, ['id', 'name']),
          text: ''
        }
      })

      this.$buefy.modal.open({
        parent: this,
        component: NoteForm,
        hasModalCard: true,
        width: 400,
        height: 400,
        props: {
          id
        }
      })
    },
    async addContact (collectionPath) {
      const self = this
      let options = []
      var permanentDocument = null
      switch(collectionPath) {
      case 'team':
        await this.$store.dispatch('users/fetchAndAdd', { limit: 0 })
        options = self.users
        break
      case 'contacts':
        // await this.$store.dispatch('operators/fetchById', self.doc.operator.id)
        let operator = _.find(this.$store.getters["operators/items"], { id: self.doc.operator.id})
        options = _.assign({}, operator.contacts)
        permanentDocument = {
          collection: 'operators',
          id: self.doc.operator.id,
          path: 'contacts'
        }
        break
      }

      const id = new Date().toString()
      this.$store.dispatch('currentDoc/set', {
        field: `${collectionPath}.${id}`,
        value: {
          id,
          name: '',
          email: '',
          phone: ''
        }
      })

      this.$buefy.modal.open({
        parent: this,
        component: ContactForm,
        hasModalCard: true,
        fullScreen: true,
        props: {
          id,
          options,
          collectionPath,
          permanentDocument,
          header: this.$t('failures.team_member')
        }
      })
    },
    async create (moduleName) {
      const self = this
      const newDoc = _.assign({}, {
        failure: makeFailureRef(self.doc),
        parent: this.parent
        // parent: this.data.parent ? _.pick(this.parent, ['id', 'number', 'part', 'parent']) : null
      })
      const id = await this.$store.dispatch(`${moduleName}/insert`, newDoc)
      this.$router.push({ name: _.trimEnd(moduleName, 's'), params: { id } })
    }
  }
}
</script>
