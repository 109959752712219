<template>
  <div
    class="panel-block"
    @click="edit"
  >
    <span class="is-pulled-left">
      <small class="is-italic">{{ date }}</small><br>
      <span
        class="tag is-primary is-light is-rounded is-small"
        :title="note.created_by.name"
      >
        {{ initials }}
      </span>
    </span>
    <span :style="{ marginLeft: '1em'}">
      {{ note.text }}
    </span>
  </div>
</template>

<script>
import NoteForm from './NoteForm'

export default {
  props: {
    note: {
      required: true
    }
  },
  computed: {
    initials () {
      return this.note.created_by.name.split(' ').map((n) => n[0]).join('')
    },
    date () {
      return moment(this.note.id).format('D.M.YY')
    }
  },
  methods: {
    edit () {
      const { id } = this.note
      this.$buefy.modal.open({
        parent: this,
        component: NoteForm,
        hasModalCard: true,
        width: 400,
        height: 400,
        props: {
          id
        }
      })
    }
  }
}
</script>
