<template>
  <div
    class="b-steps"
  >
    <nav
      class="steps"
    >
      <ul class="step-items">
        <li
          v-for="(childItem, index) in items"
          :key="childItem.value"
          class="step-item"
          :class="[childItem.type || type, {
            'is-active': index <= activeStep
          }]"
        >
          <a
            class="step-link is-clickable"
            @click="click(childItem.value)"
          >
            <div class="step-marker">
              <b-icon
                v-if="childItem.icon"
                :icon="childItem.icon"
                :size="size"
              />
            </div>
            <div class="step-details">
              <span class="step-title">{{ childItem.label }}</span>
            </div>
          </a>
        </li>
      </ul>
    </nav>
    <section
      class="step-content is-transitioning"
    >
      <slot />
    </section>
  </div>
</template>

<script>

export default {
  props: {
    items: {
      type: Array
    },
    type: {
      type: String,
      default: 'is-default'
    },
    size: {
      default: 'is-large',
      type: String
    },
    activeStep: {
      type: Number
    }
  },
  methods: {
    click(value) {
      this.$emit('click', value)
    }
  }
}
</script>
