<template>
  <a-card
    :editable="false"
  >
    <b-field
      :label="$t('failures.dispatchFailureParts.notes')"
    >
      <b-input
        v-model="note"
        type="textarea"
      />
    </b-field>
    <b-field
      :label="$t('common.internal_note')"
    >
      <b-input
        v-model="internalNote"
        type="textarea"
      />
    </b-field>
    <b-button
      :disabled="disabled"
      :loading="disabled"
      type="is-success"
      @click="makeFailureSupply"
    >
      {{ $t('failures.dispatchFailureParts.make_delivery_note') }}
    </b-button>
    </div>
  </a-card>
</template>

<script>

export default {
  props: {
    supplierName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      note: null,
      internalNote: null,
      disabled: false
    }
  },
  methods: {
    async makeFailureSupply () {
      this.disabled = true
      const data = _.pick(this, ['supplierName', 'note', 'internalNote'])
      const id = await this.$store.dispatch('failureParts/makeFailureSupply', data)
      this.$emit('close')
      this.$router.push({ name: 'failureSupplie', params: { id } })
    }
  }
}
</script>
