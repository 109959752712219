<template>
  <a-card>
    <template
      #header-title
    >
      <b-icon
        icon="wrench"
      />
      {{ $t('failures.service.header') }}
    </template>
    <div>
      <span v-if="totalWorkTime">
        {{ $t('failures.service.totalWorkTime', { totalWorkTime }) }}
      </span>
      <br>
      <span v-if="doc.service.travel || doc.service.distance">
        {{ $t('failures.service.travelTimeDistance', {
          travelTime: doc.service.travel,
          distanceKm: doc.service.distance
        }) }}
      </span>
    </div>

    <template
      #form
    >
      <b-field grouped>
        <a-input
          :label="$t('failures.service.inspection.label', { hours: nodesSum('inspection') })"
          :placeholder="$t('failures.service.inspection.placeholder')"
          field="service.inspection"
          type="number"
        />

        <a-input
          :label="$t('failures.service.repair.label', { hours: nodesSum('repair') })"
          :placeholder="$t('failures.service.repair.placeholder')"
          field="service.repair"
          type="number"
        />

        <a-input
          :label="$t('failures.service.testing.label', { hours: nodesSum('testing') })"
          :placeholder="$t('failures.service.testing.placeholder')"
          field="service.testing"
          type="number"
        />
      </b-field>

      <b-field grouped>
        <a-input
          field="service.travel"
          type="number"
        />

        <a-input
          field="service.distance"
          type="number"
        />
      </b-field>
    </template>
  </a-card>
</template>

<script>
export default {
  computed: {
    totalWorkTime() {
      const { inspection, repair, testing } = this.doc.service
      const extraHours = Number(inspection) + Number(repair) + Number(testing)

      const nodesHours =
        Number(this.nodesSum('inspection'))
        + Number(this.nodesSum('repair'))
        + Number(this.nodesSum('testing'))

      return nodesHours + extraHours
    },
    totalTime() {
      return this.totalWorkTime + this.doc.service.travel
    }
  },
  methods: {
    nodesSum( type ) {
      return _.sumBy(this.$store.getters["failureNodes/currentFailureItems"], node => node.service && node.service[type] ? Number(node.service[type]) : 0)
    }
  }
}
</script>
