<template>
  <div>
    <a-steps
      :items="items"
      :type="type"
      :active-step="activeStep"
      :has-navigation="false"
      @click="modifyStep"
    />
  </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import ASteps from '@/components/ASteps'

export default {
  components: {
    ASteps
  },
  computed: {
    reported () {
      const at = moment(this.doc.progress.reported.at).format('LL')
      const by = this.doc.progress.reported.by ? this.doc.progress.reported.by.name : ''
      return this.$t('failures.progressBar.reported', { at, by })
    },
    serviced () {
      const end = this.doc.progress.serviced.at ? moment(this.doc.progress.serviced.at) : moment()
      // console.log('end', end)
      const duration = moment.duration(end.diff(moment(this.doc.progress.reported.at)))
      // console.log('duration', duration)
      const days = _.round(duration.asDays())

      return this.doc.progress.serviced.at
        ? this.$t('failures.progressBar.repair_time', { days }) : this.$t('failures.progressBar.unresolved_time', { days })
    },
    completedOrEstimated () {
      if(this.doc.progress.completed.at) {
        const completed_at = moment(this.doc.progress.completed.at).format('LL')
        return this.$t('failures.progressBar.completed_at', { completed_at })
      } else if(this.doc.progress.estimated.at) {
        const estimated_at = moment(this.doc.progress.estimated.at).format('LL')
        return this.$t('failures.progressBar.estimated_at', { estimated_at })
      }
      return ''
      /*} else {
        if(!this.estimatedHistory.length) {
          return _.reduce(_.values(this.estimatedHistory), (result, item) => result += moment(item.at.toDate()).format('LL') + '<br/>', '')
        }*/
    },
    items() {
      return this.doc && this.doc.progress ? [
        { value: 'reported', label: this.reported, icon: 'phone-in-talk'},
        { value: 'serviced', label: this.serviced, icon: 'wrench'},
        { value: 'completedOrEstimated', label: this.completedOrEstimated, icon: this.doc.progress.completed.at ? 'check-all' : 'calendar'}
      ] : []
    },
    isAfterEstimated() {
      return !this.doc.progress.serviced.at && this.doc.progress.estimated.at && moment(this.doc.progress.estimated.at).isBefore(moment())
    },
    estimatedDiff() {
      const estimated_at = this.doc.progress.estimated.at
      return estimated_at ? moment(estimated_at).startOf('day').diff(moment().startOf('day'), 'days') : null
    },
    type() {
      if(this.doc.progress.serviced.at) {
        return 'is-success'
      } else if(_.isNumber(this.estimatedDiff)) {
        if(this.estimatedDiff < 1) {
          return 'is-danger'
        }  if(this.estimatedDiff < 8) {
          return 'is-warning'
        }
        return 'is-info'
      }
      return 'is-info'
    },
    activeStep () {
      if (this.doc.progress.estimated.at || this.doc.progress.serviced.at) {
        return 2
      }
      if (this.doc.progress.reported.at) {
        return 1
      }
      return 0
    },
    estimatedHistory() {
      const { history } = this.doc.progress.estimated
      return history && _.values(history).length ? _.values(history) : []
    }
  },
  methods: {
    modifyStep (step) {
      const self = this
      if (this.user.role === 'admin') {
        if(step === 'completedOrEstimated') {
          step = self.doc.progress.completed.at ? 'completed' : 'estimated'
        }

        this.openDatepicker({
          field: `progress.${step}.at`,
          header: this.$t(`failures.props.progress.${step}.at`),
          events: {
            close() {
              self.$store.dispatch('currentDoc/set', {
                field: `progress.${step}.by`,
                value: _.pick(self.user, ['id', 'name'])
              })
            }
          }
        })
      }
    }
  }
}
</script>
