<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ $t('failures.noteForm.header') }}
      </p>
    </header>

    <section class="modal-card-body">
      <a-card
        :editable="false"
      >
        <a-input
          :field="`notes.${id}.text`"
          type="textarea"
          :label="$t('failures.noteForm.type_a_note')"
        />
      </a-card>
    </section>
    <footer class="modal-card-foot">
      <b-button
        type="is-danger"
        icon-left="delete"
        @click="remove"
      >
        {{ $t('common.delete') }}
      </b-button>
      <button
        class="button is-success"
        type="button"
        @click="close"
      >
        <span>{{ $t('common.ok') }}</span>
      </button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    id: {
      required: true
    }
  },
  methods: {
    close () {
      this.$parent.close()
    },
    remove () {
      this.$store.dispatch('failures/delete', `${this.doc.id}.notes.${this.id}`)
      this.close()
    }
  }
}
</script>

<style scoped>
.modal-card {
    width: auto;
}
.modal-card-body {
    width: auto;
}
</style>
