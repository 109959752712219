<template>
  <a-card
    :editable="false"
  >
    <template
      #header-buttons
    >
      <label
        v-if="keys(failurePartsToDispatch).length"
        :style="{ color: 'black'}"
      >
        {{ $t('failures.failureParts.generate_supply_for') }}
      </label>
      <b-select
        :value="supplier"
        :label="$t('failures.failureParts.select_supplier')"
        @input="dispatchFailureParts"
      >
        <option
          v-for="option in [null].concat(keys(failurePartsToDispatch))"
          :key="option"
          :value="option"
        >
          {{ option }}
        </option>
      </b-select>
      </b-field>
    </template>

    <node-tree
      :key="`failureTree-${doc.id}`"
      v-model="parent"
      :nodes="failureNodes"
      :parts="failureParts"
      :options="options"
      @input="selectParent"
      @remove="removeNode"
      @duplicate="duplicatePart"
      @hideParts="hideParts"
      @hideHeaders="hideHeaders"
    />
  </a-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { get, keys, find } from 'lodash'
import NodeTree from '@/components/NodeTree'
import DispatchFailureParts from './DispatchFailureParts'

export default {
  components: {
    NodeTree,
    DispatchFailureParts
  },
  computed: {
    ...mapGetters({
      currentDoc: 'currentDoc/currentDoc',
      failureRel: 'currentDoc/makeRel',
      failureParts: 'failureParts/currentFailureItems',
      failureNodes: 'failureNodes/currentFailureItems',
      currentFailureParts: 'failureParts/currentFailureItems',
      dispatchedFailureParts: 'failureParts/dispatchedCurrentFailureParts',
      undispatchedFailureParts: 'failureParts/undispatchedCurrentFailureParts',
      failurePartsToDispatch: 'failureParts/currentFailurePartsToDispatch'
    }),
    docProps () {
      return {
        failure: this.failureRel,
        customer: this.doc ? this.doc.customer : null
      }
    },
    options () {
      const { hideParts, hideHeaders } = this.user.routes.failure.failureParts
      return {
        canSelect: this.$store.getters["currentDoc/canUpdate"],
        canUpdate: this.$store.getters["currentDoc/canUpdate"],
        canRemove: this.$store.getters["currentDoc/canUpdate"],
        selectOnly: false,
        hideParts,
        hideHeaders
      }
    }
  },
  data () {
    return {
      supplier: null,
      parent: false
    }
  },
  async created () {
    await this.$store.dispatch('currentDoc/loadChildren', {
      module: 'failureNodes',
      ref: 'failure.id'
    })

    await this.$store.dispatch('currentDoc/loadChildren', {
      module: 'failureParts',
      ref: 'failure.id'
    })

    let selectedNodeId = this.$store.state.route.query.selectedNode || this.$store.state.route.query.ramsNode
    if(selectedNodeId) {
      let nodeInNodesTree = find(this.failureNodes, { id: selectedNodeId })
      if(nodeInNodesTree) {
        this.parent = nodeInNodesTree
      }
    }
  },
  methods: {
    get,
    keys,
    async create () {
      const id = await this.$store.dispatch(`failureParts/insert`, {
        failure: {
          id: this.currentDoc.id
        }
      })
      this.$router.push({ name: 'failurePart', params: { id } })
    },
    selectParent(parent) {
      this.$emit('selectParent', parent)
    },
    dispatchFailureParts (supplierName) {
      console.log('dispatch', supplierName)
      if (supplierName) {
        this.$buefy.modal.open({
          parent: this,
          component: DispatchFailureParts,
          hasModalCard: true,
          width: 400,
          props: {
            supplierName
          }
        })
      }
    },
    async removeNode (failureNode) {
      const self = this
      this.$buefy.dialog.confirm({
        message: this.$t('failures.failureParts.remove_node_confirm'),
        type: 'is-warning',
        confirmText: this.$t('common.delete'),
        onConfirm: async () => {
          await self.setParent(failureNode, 'failureParts')
          await self.setParent(failureNode, 'failureNodes')
          self.$store.dispatch(`failureNodes/delete`, failureNode.id)
        }
      })
    },
    async partToNode (value) {
      await this.setParent(value, 'failureParts')
      await this.setParent(value, 'failureNodes')

      this.$store.dispatch(`failureNodes/delete`, value.id)
      this.close()
    },
    async setParent (value, collection) {
      let parent = (value && value.parent) ? _.assign({}, value.parent) : null
      _.forEach(this[collection], async item => {
        if (item.parent && item.parent.id === value.id) {
          // Vue.set(item, 'parent', parent)
          await this.$store.dispatch(`${collection}/set`, {
            id: item.id,
            parent
          })
        }
      })
    },
    async duplicatePart (part) {
      let newPart = _.cloneDeep(part)
      _.unset(newPart, 'id')
      newPart.number = null
      newPart.position = null
      newPart.failureSupply = null
      if(newPart.replacement && newPart.replacement.number) {
        newPart.replacement.number = null
      }
      const id = await this.$store.dispatch(`failureParts/insert`, newPart)
      this.$router.push({ name: 'failurePart', params: { id } })
      this.close()
    },
    hideParts (value) {
      this.$store.dispatch('user/update', {
        path: `routes.failure.failureParts.hideParts`,
        value
      })
    },
    hideHeaders (value) {
      this.$store.dispatch('user/update', {
        path: `routes.failure.failureParts.hideHeaders`,
        value
      })
    }
  }

}
</script>
