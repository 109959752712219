<template>
  <section>
    <type-vehicle-autocomplete
      field="vehicle"
      options="vehicles"
      option-label="number"
      placeholder="Please select a vehicle"
    />

    <a-input
      label="Failure description"
      placeholder="Please describe the failure thoroughly"
      type="textarea"
      field="description"
    />

    <a-input
      field="odometer"
      placeholder="Please fill in odometer state"
    />

    <button
      class="button is-primary"
      @click="sendRequest"
    >
      Send request to service
    </button>
  </section>
</template>

<script>
import TypeVehicleAutocomplete from '@/components/TypeVehicleAutocomplete'

export default {
  components: {
    TypeVehicleAutocomplete
  },
  methods: {
    sendRequest () {
      const { description, odometer } = this.doc
      if (!(description && odometer)) {
        this.$buefy.dialog.alert('Please fill all form fields properly')
      } else {
        this.$store.dispatch('currentDoc/set', {
          field: 'state',
          value: 'initial'
        })
        this.$router.push({ name: 'failures' })
        this.$buefy.dialog.alert('Thanks for your inquiry, you will be notified of its progress')
      }
    }
  }
}
</script>
