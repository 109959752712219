<template>
  <div class="card">
    <div class="card-content">
      <div
        class="content"
        @click="showMessage"
      >
        <a class="title is-6">
          {{ mail.message.subject }}
        </a>
        <b-message
          v-if="mail.delivery && mail.delivery.error"
          :title="$t('failures.mail.delivery_error')"
          type="is-danger"
          has-icon
          aria-close-label="Close message"
          @close="removeMessage"
        >
          {{ mail.delivery.error }}
        </b-message>
        <b-icon
          v-if="$store.getters['user/isSuperAdmin']"
          icon="delete"
          @click.native.stop.prevent="removeMessage"
        />
      </div>

      <div class="media">
        <div class="media-left">
          <small class="is-italic">{{ date }}</small><br>
        </div>
        <div class="media-content">
          <span
            v-for="recipient in mail.to"
            :key="recipient"
          >
            <b-icon
              icon="account-circle"
            />
            {{ recipient }}
            <br>
          </span>
        </div>
      </div>

      <div class="content">
        <span
          v-for="attachment in mail.message.attachments"
          :key="attachment.path"
          class="tag is-primary is-light is-rounded is-small"
        >
          <a
            :href="attachment.path"
            target="_blank"
          > {{ attachment.filename }} </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    mail: {
      required: true
    }
  },
  computed: {
    date () {
      return moment(this.mail.created_at).format('D.M.YY')
    }
  },
  methods: {
    showMessage () {
      this.$buefy.dialog.alert({
        title: this.mail.message.subject,
        message: this.mail.message.html,
        confirmText: this.$t('common.close')
      })
    },
    removeMessage() {
      this.$buefy.dialog.alert({
        message: this.$t('failures.mail.remove_message'),
        confirmText: this.$t('failures.mail.remove_confirm'),
        onConfirm: () => this.$store.dispatch(`mails/delete`, this.mail.id)
      })
    }
  }
}
</script>
